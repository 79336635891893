import CardEmployeeListingSingle from "./card.employee.listing.single";
import PropTypes from "prop-types";

const CardEmployeeListing = ({
  persons,
  header,
  showCategories = false,
  className,
}) => (
  <div className={className}>
    {header && <h2>{header}</h2>}
    <div className="u-padding-top-bottom--large c-card-employee-listing">
      {persons &&
        persons.map(person => (
          <CardEmployeeListingSingle
            person={person}
            key={person.title}
            showCategories={showCategories}
          />
        ))}
    </div>
  </div>
);

CardEmployeeListing.propTypes = {
  persons: PropTypes.array,
};

export default CardEmployeeListing;
